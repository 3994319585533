<template>
  <div>
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          responsive
          :items="list"
          :fields="columns"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(image_url)="data">
            <div
              class="position-relative image"
              v-bind:style="{
                'background-image': 'url(' + data.item.image_url + ')',
              }"
            ></div>
          </template>
          <template v-slot:cell(name)="data">
            <div class="text-left">
              <router-link :to="'/coupon/details/' + data.item.id">
                <b-button variant="link" class="name-link p-0">
                  {{ data.item.name }}
                </b-button>
              </router-link>
              <div class="line-clamp2 description">{{ data.item.detail }}</div>
              <div
                v-if="data.item.privilege_name"
                class="badge badge-secondary"
              >
                Used : {{ data.item.privilege_name }}
              </div>
            </div>
          </template>
          <template v-slot:cell(validFrom)="data">
            <div class="justify-content-center">
              <div>{{ $moment(data.item.validFrom).format("DD/MM/YYYY") }}</div>
              <div class="time">
                ({{ $moment(data.item.validFrom).format("HH:mm:ss") }})
              </div>
            </div>
          </template>
          <template v-slot:cell(validTo)="data">
            <div class="justify-content-center">
              <div>{{ $moment(data.item.validTo).format("DD/MM/YYYY") }}</div>
              <div class="time">
                ({{ $moment(data.item.validTo).format("HH:mm:ss") }})
              </div>
            </div>
          </template>
          <template v-slot:cell(status)="data">
            <div class="d-flex justify-content-center">
              <span class="active" v-if="data.item.status === 1"
                >Enable</span
              >
              <span class="inactive" v-if="data.item.status === 0"
                >Disable</span
              >
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array,
    },
    columns: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: #f0b6c7;
  color: var(--font-color);
}
.active {
  color: #10af10;
}
.inactive {
  color: red;
}
.time {
  color: #afafaf;
}
.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.image {
  width: 50%;
  height: 50%;
  padding-top: 42.9%;
  margin-left: 25%;
  background-position: center !important;
  background-size: cover;
  background-repeat: no-repeat;
}
.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  font-weight: 100;
}
.description {
  color: #b1b1b1;
}
</style>
